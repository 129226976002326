import ModalController from "../components/modal_controller";

export default class extends ModalController {
    get categories() {
        const select  = this.element.querySelector("select[data-name=\"categories\"]");
        const choices = select["choices"].instance;
        return choices.getValue().map(choice => {
            return {
                id: choice.value,
                name: choice.label,
            };
        });
    }

    set categories(values) {
        const select  = this.element.querySelector("select[data-name=\"categories\"]");
        const choices = select["choices"].instance;

        if (values.length === 0) {
            choices.removeActiveItems();
            return;
        }

        choices.setChoiceByValue(values);
    }

    open(src) {
        this.opener = src;

        // Selecting categories if any
        this.categories = Array.from(this.opener.querySelectorAll("input[type=\"hidden\"]")).map(input => input.value);

        // Updating modal text
        this.element.querySelector("span[data-name=\"caller\"]").innerText = getModalText(this.opener);

        super.open();
    }

    save() {
        const saveEvent = new CustomEvent("modal:save", {
            bubbles: false,
            detail: {
                categories: this.categories,
            },
        });
        this.opener.dispatchEvent(saveEvent);
        this.close();
    }

    close() {
        this.opener     = undefined;
        this.categories = [];
        super.close();
    }
}

function getModalText(opener) {
    switch (true) {
        case opener.dataset["mandatorycontent-TrainingTarget"] != undefined:
            return "training assets";
        case opener.dataset["mandatorycontent-PoliciesTarget"] != undefined:
            return "policy documents";
    }
}
