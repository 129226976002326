import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["program", "target", "company", "group", "button"];

    validate() {
        const templateID     = this.programTarget.value;
        const selectedTarget = this.targetTargets.find(input => input.checked).value;
        const companies      = this.companyTargets.filter(checkbox => checkbox.checked).length;
        const groups         = this.groupTargets.filter(checkbox => checkbox.checked).length;

        if (templateID === "") {
            this.buttonTarget.disabled = true;
            return;
        }

        if (selectedTarget === "CUSTOM" && companies === 0) {
            this.buttonTarget.disabled = true;
            return;
        }

        if (selectedTarget === "GROUPS" && groups === 0) {
            this.buttonTarget.disabled = true;
            return;
        }

        this.buttonTarget.disabled = false;
    }
}
